// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Container, Input, CardBody, Card, Button } from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import DetailUserAnswer from "components/Modals/DetailUserAnswer";

const UserRegisteredPrerequisiteEvent = ({ match }) => {
  const location = useLocation();
  const { eventId, prerequisite } = location.state;
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const navigation = useHistory();
  const [index, setIndex] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const prerequisiteType = {
    checkbox: "checkbox",
    shortAnswer: "short_answer",
    paragraf: "paragraf",
    fileUpload: "file_upload",
    multipleChoice: "multiple_choice",
  };
  const useAuth = UseAuth();

  useEffect(() => {
    getUserPrerequisiteEventRegistered(eventId);
  }, []);

  const handleClick = (i) => {
    setModalIndex(i);
    setIsOpen(!isOpen);
  };

  const getUserPrerequisiteEventRegistered = (_id) => {
    API.get("/prerequisite/getPrerequisiteUserAnswer/" + _id, {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        // console.log(res.data.data);
        setLoading(false);
        setUsers(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  if (loading) return <Loading />;

  return (
    <Container style={{ width: "100%" }} className="pt-8" fluid>
      <button
        className="color-hievent"
        style={{
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "fit-content",
          padding: 10,
        }}
        onClick={() => navigation.goBack()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
            fill="#9870C5"
          />
        </svg>
        Kembali
      </button>
      <div
        className="d-flex flex-row align-items-center justify-content-center mt-5 mx-4 w-100 pb-3"
        style={{ overflowX: "auto" }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setIndex(1)}
          className={
            index === 1
              ? "text-md font-weight-bold color-hievent"
              : "text-md font-weight-bold"
          }
        >
          Responder
        </span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setIndex(2)}
          className={
            index === 2
              ? "text-md font-weight-bold color-hievent mx-4"
              : "text-md font-weight-bold mx-4"
          }
        >
          Overview
        </span>

        <span
          style={{ cursor: "pointer" }}
          onClick={() => setIndex(3)}
          className={
            index === 3
              ? "text-md font-weight-bold color-hievent mr-4"
              : "text-md font-weight-bold mr-4"
          }
        >
          My Question
        </span>
      </div>

      {index === 1 && (
        <Row style={{ padding: "20px 2.5vw", width: "100%" }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr
                style={{
                  borderTop: "solid 1px #DADADA",
                  borderBottom: "solid 1px #DADADA",
                }}
              >
                <th style={{ fontWeight: 600, padding: "10px 0px" }}>
                  Nama User
                </th>
                <th style={{ padding: "10px 0px", fontWeight: 600 }}>Tempat</th>
                <th style={{ padding: "10px 0px", fontWeight: 600 }}>Detail</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((item, i) => (
                <tr
                  key={i}
                  style={{
                    borderBottom: "solid 1px #DADADA",
                    color: "#737373",
                  }}
                >
                  <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                    {item?.name}
                  </td>
                  <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                    {item?.city}
                  </td>
                  <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                    <Button
                      onClick={() => handleClick(i)}
                      style={{
                        backgroundColor: "#155BCD",
                        color: "#ffffff",
                      }}
                      color="#155BCD"
                    >
                      Detail
                    </Button>
                    {i === modalIndex && (
                      <DetailUserAnswer
                        props={{
                          isOpen,
                          setIsOpen,
                          item,
                          modalIndex,
                          setModalIndex,
                          prerequisite,
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      )}

      {index === 3 &&
        prerequisite.map((item, i) => {
          return (
            <div
              style={{ width: "65%", margin: "0 auto" }}
              className="d-flex justify-content-center"
            >
              <div
                style={{ padding: "20px 2.5vw", width: "100%" }}
                className=" d-flex align-items-start justify-content-between"
              >
                <div style={{ width: "65%" }}>
                  <div
                    style={{
                      gap: "10px",
                      width: "100%",
                    }}
                    className="d-flex flex-column align-items-start "
                  >
                    {(item.prerequisiteType === prerequisiteType.shortAnswer ||
                      item.prerequisiteType === prerequisiteType.paragraf ||
                      item.prerequisiteType ===
                        prerequisiteType.fileUpload) && (
                      <div
                        style={{
                          width: "100%",
                        }}
                        className=" rounded"
                      >
                        <div
                          style={{
                            border: "solid 1px #CAD1D7",
                            width: "100%",
                            padding: "10px",
                          }}
                          className=" rounded"
                        >
                          {prerequisite[i].title}
                        </div>
                        {item.prerequisiteType ===
                          prerequisiteType.fileUpload && (
                          <p
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            User dapat mengupload file dalam format PNG, JPG,
                            JPEG, PDF
                          </p>
                        )}
                      </div>
                    )}

                    {(item.prerequisiteType ===
                      prerequisiteType.multipleChoice ||
                      item.prerequisiteType === prerequisiteType.checkbox) && (
                      <div
                        style={{
                          width: "100%",
                          padding: "20px",
                          border: "solid 1px #CAD1D7",
                          gap: "20px",
                        }}
                        className="rounded d-flex flex-column"
                      >
                        <div
                          style={{
                            border: "solid 1px #CAD1D7",
                            width: "100%",
                            padding: "10px",
                          }}
                          className=" rounded"
                        >
                          {prerequisite[i].title}
                        </div>
                        {item.choiceAnswer.map((choise, j) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: `${
                                  item.prerequisiteType ===
                                  prerequisiteType.multipleChoice
                                    ? "100%"
                                    : "0%"
                                }`,
                                border: "solid 1px #cad1d7",
                              }}
                            ></div>

                            <Input
                              style={{
                                width: "90%",
                              }}
                              type="text"
                              placeholder={`Option ${j + 1}`}
                              value={prerequisite[i]?.choiceAnswer?.[j] || ""}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "25%",
                  }}
                >
                  <div
                    style={{
                      padding: "9px 15px",
                      width: "100%",
                    }}
                    className="text-white bg-hievent rounded "
                  >
                    <p
                      style={{
                        padding: "0px",
                        margin: "0px",
                        fontWeight: "600",
                        fontSize: "13px",
                        textTransform: "capitalize",
                      }}
                    >
                      {prerequisite[i].prerequisiteType.split("_").join(" ")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Container>
  );
};

export default UserRegisteredPrerequisiteEvent;
