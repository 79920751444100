import {
  Badge,
  Button,
  Modal,
  InputGroupAddon,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
const DetailUserAnswer = ({ props }) => {
  const prerequisiteType = {
    checkbox: "checkbox",
    shortAnswer: "short_answer",
    paragraf: "paragraf",
    fileUpload: "file_upload",
    multipleChoice: "multiple_choice",
  };
  const handleClose = () => {
    props?.setIsOpen(!props?.isOpen);
    props?.setModalIndex(null);
  };
  console.log(props.item.answers);
  return (
    <Modal
      size="lg"
      className="modal-dialog-centered modal-info"
      contentClassName="bg-white"
      isOpen={props.isOpen}
      toggle={() => handleClose()}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => handleClose()}
        >
          <span
            aria-hidden={true}
            style={{
              color: "black",
            }}
          >
            ×
          </span>
        </button>
      </div>
      <div className="modal-body p-0">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px",
            padding: "10px 30px 30px 30px",
          }}
        >
          <h2 className="mb-0 text-black">{props?.item?.name}</h2>
          {props?.prerequisite?.map((item, i) => {
            return (
              <div
                style={{
                  gap: "10px",
                  width: "100%",
                  color: "#000000",
                }}
                className="d-flex flex-column align-items-start "
              >
                {(item.prerequisiteType === prerequisiteType.shortAnswer ||
                  item.prerequisiteType === prerequisiteType.paragraf ||
                  item.prerequisiteType === prerequisiteType.fileUpload) && (
                  <div
                    style={{
                      width: "100%",
                    }}
                    className=" rounded"
                  >
                    <div
                      style={{
                        border: "solid 1px #CAD1D7",
                        width: "100%",
                        padding: "10px",
                      }}
                      className=" rounded"
                    >
                      {item.title}
                    </div>
                    {item.prerequisiteType === prerequisiteType.fileUpload && (
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        User dapat mengupload file dalam format PNG, JPG, JPEG,
                        PDF
                      </p>
                    )}
                  </div>
                )}

                {(item.prerequisiteType === prerequisiteType.multipleChoice ||
                  item.prerequisiteType === prerequisiteType.checkbox) && (
                  <div
                    style={{
                      width: "100%",
                      padding: "20px",
                      border: "solid 1px #CAD1D7",
                      gap: "20px",
                    }}
                    className="rounded d-flex flex-column"
                  >
                    <div
                      style={{
                        border: "solid 1px #CAD1D7",
                        width: "100%",
                        padding: "10px",
                      }}
                      className=" rounded"
                    >
                      {item.title}
                    </div>
                    {item.choiceAnswer.map((choise, j) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            // backgroundColor:
                            //   choise === props?.item?.answer[j].answer
                            //     ? "#9870C5"
                            //     : "#ffffff",
                            borderRadius: `${
                              item.prerequisiteType ===
                              prerequisiteType.multipleChoice
                                ? "100%"
                                : "0%"
                            }`,
                            border: "solid 1px #cad1d7",
                          }}
                        ></div>

                        <div
                          style={{
                            width: "90%",
                            border: "solid 1px #CAD1D7",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          {item?.choiceAnswer?.[j] || ""}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default DetailUserAnswer;
