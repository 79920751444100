// import Breadcumbs from "components/Partials/Breadcumbs";
import Loading from "components/Partials/Loading";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import { Row, Col, Container, CardBody, Card } from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import moment from "moment";
import { formatToIDR } from "utils/helpers";

const UserRegisteredPaidEvent = ({ match }) => {
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const navigation = useHistory();

  const useAuth = UseAuth();

  useEffect(() => {
    getUserPaidEventRegistered(match.params.id);
  }, [match.params.id]);

  const getUserPaidEventRegistered = (_id) => {
    API.get(
      "eventRegistrations/getUserAttendEvents/" +
        _id.split("=")[0] +
        `?page=0&perPage=10&status=registered`,
      {
        headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
      }
    )
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        setUsers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  if (loading) return <Loading />;

  return (
    <Container style={{ width: "100%" }} className="pt-8" fluid>
      <button
        className="color-hievent"
        style={{
          backgroundColor: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "fit-content",
          padding: 10,
        }}
        onClick={() => navigation.goBack()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
            fill="#9870C5"
          />
        </svg>
        Kembali
      </button>
      <Row style={{ padding: "20px 2.5vw", width: "100%" }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr
              style={{
                borderTop: "solid 1px #DADADA",
                borderBottom: "solid 1px #DADADA",
              }}
            >
              <th style={{ fontWeight: 600, padding: "10px 0px" }}>
                Nama User
              </th>
              <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                Tgl Transaksi
              </th>
              <th style={{ padding: "10px 0px", fontWeight: 600 }}>Jumlah</th>
              <th style={{ padding: "10px 0px", fontWeight: 600 }}>
                Judul Event
              </th>
              <th style={{ padding: "10px 0px", fontWeight: 600 }}>Bukti</th>
            </tr>
          </thead>
          <tbody>
            {users.data.map((item) => (
              <tr
                style={{
                  borderBottom: "solid 1px #DADADA",
                  color: "#737373",
                }}
              >
                <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                  {item?.user?.name}
                </td>
                <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                  {moment(item?.payment?.createdAt).format("DD MMMM YYYY")}
                </td>
                <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                  {formatToIDR(item?.order?.totalAmount ?? 0)}
                </td>
                <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                  {item?.event?.title}
                </td>
                <td style={{ padding: "10px 0px", fontWeight: 500 }}>
                  <a
                    href={item?.payment?.attachment?.url || "#"} // Menggunakan URL langsung dari server
                    target="_blank"
                    rel="noopener noreferrer"
                    // download={item?.payment?.attachment?.name || "payment.jpeg"}
                  >
                    {item?.payment?.attachment?.name || "Download Attachment"}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
    </Container>
  );
};

export default UserRegisteredPaidEvent;
